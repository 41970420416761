<app-shell-page-root *ngIf="fields.$ | async; let $;">
  <ng-container content>
    <div class="text-wrapper">
      <img class="party-img"
        src="assets/party-popper.png">
      <div class="welcome-text">
        Welcome!
      </div>
      <div class="supporting-text">
        You are now a member of {{$.currentOrganizationName$}}
      </div>
      <div class="sub-text">
        Click on one of the apps below to get started
      </div>
    </div>
    <div class="app-row">
      <div *ngIf="$.userApplications$.length === 0">
        You currently do not have access to any application
      </div>
      <a class="app-wrapper"
        *ngFor="let app of $.userApplications$"
        [href]="app.appLink"
        target="_blank">
        <div class="app-icon">
          {{app.name[0]}}
        </div>
        <div class="app-title">
          <div class="tittle-text">{{app.name}}</div>
        </div>
        <div class="app-description">
          <div class="description-text">{{app.description}}</div>
        </div>
      </a>
    </div>
  </ng-container>
</app-shell-page-root>