import { OrgAdminAppGuard } from 'g2i-ng-auth';
import { ShellPageProfileCreateComponent } from 'shared/components/shell-profile-create/shell-profile-create.component';
import { AppRoute, shellRoutes } from 'shared/constants/route.consts';
import { ShellAppGuard } from 'shared/services/shell-app.guard';

import { InvalidInviteComponent } from './main/invalid-invite/invalid-invite.component';
import { MainResolver } from './main/main.resolver';
import { WelcomeComponent } from './main/welcome/welcome.component';
import { WelcomeResolver } from './main/welcome/welcome.resolver';


export const routes: AppRoute[] = [
  ...shellRoutes,
  {
    path: 'app',
    canActivate: [ShellAppGuard, OrgAdminAppGuard],
    runGuardsAndResolvers: 'always', // Required for OrganizationAppGuard to function smoothly
    children: [
      {
        path: ':organizationid',
        loadChildren: () => import('./main/main.routes').then(m => m.routes),
        resolve: { data: MainResolver },
      },
    ],
  },
  {
    path: 'invite',
    children: [
      {
        path: 'expired',
        component: InvalidInviteComponent,
      },
      {
        path: 'profile',
        component: ShellPageProfileCreateComponent,
      },
      {
        path: ':organizationid',
        children: [
          {
            path: 'welcome',
            component: WelcomeComponent,
            resolve: { data: WelcomeResolver },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];
