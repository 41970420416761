import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ShellPageRootComponent } from 'shared/components/shell-page-root/shell-page-root.component';

@Component({
  selector: 'app-invalid-invite',
  templateUrl: './invalid-invite.component.html',
  styleUrls: ['./invalid-invite.component.scss'],
  standalone: true,
  imports: [CommonModule, ShellPageRootComponent],
})
export class InvalidInviteComponent {
}
