import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { G2iAuthService } from 'g2i-ng-auth';
import { sharedState } from 'projects/app-admin/src/shared/store/shared.state';
import { combineLatest } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { ApiApplicationService } from 'shared/api/organization/application/api-application.service';
import { pipe } from 'shared/utils/rxjs.utils';

@Injectable({ providedIn: 'root' })
export class WelcomeResolver implements Resolve<any> {

  constructor(
    readonly apiApplication: ApiApplicationService,
    readonly authService: G2iAuthService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { organizationid } = route.param;
    return pipe(
      concatMap(() => combineLatest([
        pipe(
          concatMap(() => this.apiApplication.listApplications()),
          tap(applications => sharedState.dispatch.applications(__filename, applications)),
        ),
        pipe(
          concatMap(() => this.apiApplication.listApplicationRoles()),
          tap(applicationRoles => sharedState.dispatch.applicationRoles(__filename, applicationRoles)),
        ),
        pipe(
          concatMap(() => this.authService.authDetails$),
          map(res => res.user.organizations.findOrThrow(o => o.organization.id === organizationid).roles),
        ),
      ])),
      map(([applications, applicationRoles, orgRoles]) => {
        const userApplications = orgRoles.map(or => {
          const userAppRole = applicationRoles.findOrThrow(ar => ar.roleId === or.id);
          return applications.findOrThrow(a => a.id === userAppRole.applicationId);
        });
        sharedState.dispatch.userApplications(__filename, userApplications);
      }),
    ).subscribe();
  }
}
