import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { settingsState } from 'projects/app-admin/src/app/main/settings/settings.state';
import { ApiTenantOrgService } from 'projects/app-admin/src/shared/api/tenant-org/api-tenant-org.service';
import { concatMap, tap } from 'rxjs/operators';
import { pipe } from 'shared/utils/rxjs.utils';

@Injectable({ providedIn: 'root' })
export class MainResolver implements Resolve<any> {

  constructor(
    readonly tenantOrgService: ApiTenantOrgService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizationId = route.param.organizationid;
    return pipe(
      concatMap(() => this.tenantOrgService.getOneOrganization({ organizationId })),
      tap(response => settingsState.dispatch.organization(__filename, response)),
    );
  }
}
