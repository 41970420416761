import { manageStateSlice } from 'g2i-ngrx-utils';
import { Application, ApplicationRole, Role } from 'shared/api/organization/api-organization.response';

export const sharedState = manageStateSlice({
  applications: new Array<Application>(),
  applicationRoles: new Array<ApplicationRole>(),
  roles: new Array<Role>(),
  userApplications: new Array<Application>(),
})({
  applications: (state, payload: Application[]) => {
    state.applications = payload;
  },
  applicationRoles: (state, payload: ApplicationRole[]) => {
    state.applicationRoles = payload;
  },
  roles: (state, payload: Role[]) => {
    state.roles = payload;
  },
  userApplications: (state, payload: Application[]) => {
    state.userApplications = payload;
  },
});
