import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { G2iAuthService } from 'g2i-ng-auth';
import { concatMap, map, tap } from 'rxjs';
import { catchErrorThenStop, pipe, takeWhileTruthy } from 'shared/utils/rxjs.utils';

import { ShellPageRootComponent } from '../shell-page-root/shell-page-root.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-shell-profile-create',
  templateUrl: './shell-profile-create.component.html',
  styleUrls: ['./shell-profile-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ShellPageRootComponent, UserProfileComponent, MatButtonModule]
})
export class ShellPageProfileCreateComponent {

  constructor(
    readonly snackbar: MatSnackBar,
    readonly authService: G2iAuthService,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly httpClient: HttpClient,
  ) {
    document.title = 'Your profile - Guud';
  }

  onCreate(userProfileComponent: UserProfileComponent) {
    const authProvider = this.authService.getAuthedProviderResponse();
    return pipe(
      concatMap(() => userProfileComponent.formGroup.validate(userProfileComponent)),
      concatMap(() => this.httpClient.post(
        `${this.authService.config.authServiceUrl}api/tenant/organization/invitation/pub/profile/${this.authService.inviteId}`,
        userProfileComponent.formGroup.value,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { headers: { Authorization: `Bearer ${authProvider.token}` } })),
      catchErrorThenStop(err => console.error('Unable to save profile', err)),
      concatMap(() => this.authService.authedProviderProcessBackboneObs()),
      takeWhileTruthy(),
      tap(success => this.authService.acceptInvite(this.authService.inviteId, success.bbToken)),
      tap(() => this.snackbar.showSuccess('Saved')),
      map(() => true),
    ).subscribe();
  }

}
