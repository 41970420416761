import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';

import { Application, ApplicationRole } from '../api-organization.response';

@Injectable({ providedIn: 'root' })
export class ApiApplicationService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.organization}/api/application`);
  }

  listApplications = () =>
    this.request<Application[]>('', 'GET');
  listApplicationRoles = () =>
    this.request<ApplicationRole[]>('app_role', 'GET');

}
