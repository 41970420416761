import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { combineManagers } from 'g2i-ngrx-utils';
import { shellState } from 'shared/store/shell.state';

import { settingsState } from '../../app/main/settings/settings.state';
import { usersState } from '../../app/main/users/users.state';
import { AppState } from './interfaces';
import { sharedState } from './shared.state';

export const managers = combineManagers({
  shell: shellState,
  user: usersState,
  settings: settingsState,
  applications: sharedState,
});

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Reducers');

export const getReducers = () => managers.reducers;
