import { Injectable } from '@angular/core';
import { ApiBaseService } from 'shared/api/api-base.service';
import { Organization } from 'shared/api/organization/api-organization.response';

import { GetOneOrganizationRequest, UpdateOrganizationRequest } from './api-tenant-org.request';

@Injectable({ providedIn: 'root' })
export class ApiTenantOrgService extends ApiBaseService {

  constructor() {
    super(() => `${this.serviceUrl.organization}/api/tenant/organization`);
  }

  getOneOrganization = (request: GetOneOrganizationRequest) =>
    this.request<Organization>(request.organizationId, 'GET');
  updateOrganization = (request: UpdateOrganizationRequest) =>
    this.request<Organization>('', 'PUT', request);

}
