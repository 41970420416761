import 'default-passive-events';

import { HttpClientModule } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  APP_ENVIRONMENT_BASE_URL,
  DEVELOPING_ON_LOCALHOST,
  G2I_AUTH_CONFIG,
  G2iAuthConfiguration,
  OrgAdminAppGuard,
} from 'g2i-ng-auth';
import { MEDIA_SERVICE_AUTH_COOKIE_NAME, MEDIA_SERVICE_URL } from 'shared/components/media/media.constants';
import { environmentOverride, providerOverrides } from 'shared/constants/api.constants';
import { HEADER_COLOR, STATE_MANAGERS, SUPPORT_ORG_ID_IN_URL } from 'shared/constants/injection-token.constants';
import { DateInputModule } from 'shared/modules/date/date-input.module';
import { ErrorService } from 'shared/services/error.service';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import * as develop from './environments/environment.develop';
import * as phase from './environments/environment.phase';
import * as prod from './environments/environment.prod';
import * as staging from './environments/environment.staging';
import { getReducers, managers, reducerToken } from './shared/store/core';

// avoid compilation warnings that these files are not being used
const unusedFiles = [prod, staging, develop, phase];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    OrgAdminAppGuard,
    {
      provide: reducerToken,
      useFactory: getReducers,
    },
    {
      provide: STATE_MANAGERS,
      useValue: managers,
    },
    {
      provide: APP_ENVIRONMENT_BASE_URL,
      useValue: environment.baseApiUrl,
    },
    {
      provide: SUPPORT_ORG_ID_IN_URL,
      useValue: false,
    },
    {
      provide: DEVELOPING_ON_LOCALHOST,
      useValue: environment.isLocalhost,
    },
    {
      provide: HEADER_COLOR,
      useValue: 'secondary',
    },
    {
      provide: MEDIA_SERVICE_AUTH_COOKIE_NAME,
      useValue: environment.authCookieName,
    },
    {
      provide: MEDIA_SERVICE_URL,
      useValue: environment.isLocalhost
        ? providerOverrides.media || environmentOverride || environment.baseApiUrl
        : environment.baseApiUrl
    },
    {
      provide: MEDIA_SERVICE_AUTH_COOKIE_NAME,
      useValue: environment.authCookieName,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    {
      /** @deprecated */
      provide: 'MEDIA_API_URL',
      useValue: environment.isLocalhost
        ? providerOverrides.media || environmentOverride || environment.baseApiUrl
        : environment.baseApiUrl,
    },
    {
      provide: G2I_AUTH_CONFIG,
      useValue: {
        authServiceUrl: environment.isLocalhost
          ? providerOverrides.auth || environmentOverride || environment.baseApiUrl
          : environment.baseApiUrl,
        cookieName: environment.authCookieName as string,
        providers: ['default'],
        debug: false,
        logo: '/assets/guud-logo.svg',
        loginLogo: '/assets/guud-logo-rebranded.svg',
        primaryColor: '#1E2334',
        invites: {
          inviteCreateProfileRoute: 'invite/profile',
          inviteFailedRoute: '/invite/expired',
          inviteSuccessRoute: (inviteId, orgId) => `/invite/${orgId}/welcome`,
        },
        noAuthUrls: ['/invite/'], // No authentication on invite urls (otherwise redirects will occur)
        snackbarCallback: (message, icon, isError, action) => {
          (this as unknown as { matSnackbar: MatSnackBar })?.matSnackbar?.show({ status: isError ? 'error' : 'success', message });
        },
      } as G2iAuthConfiguration,
    },
    importProvidersFrom(
      HttpClientModule,
      MatSnackBarModule,
      BrowserModule,
      BrowserAnimationsModule,
      DateInputModule,
      MatDialogModule,
      RouterModule.forRoot(routes),
      StoreModule.forRoot(reducerToken, {
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      }),
      StoreDevtoolsModule.instrument({
        name: 'xguud-admin',
        maxAge: 25,
      }),
    ),
  ],
}).catch(err => console.error(err));

