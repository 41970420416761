<div class="page-wrapper">
  <div class="side-banner mobile-banner">
    <img class="guud-logo" src="assets/guud-logo.svg">
    <img class="banner-image" src="assets/two-people.png">
    <div class="info-text">
      <div><a href="https://g2i-hq.atlassian.net/servicedesk/customer/portal/2" target="_blank" >Help center</a></div>
    </div>
  </div>
  <div class="main-content">
    <ng-content select=[content]>
    </ng-content>
  </div>
</div>
