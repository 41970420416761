import { manageStateSlice } from 'g2i-ngrx-utils';
import { TrackingProvider } from 'shared/api/asset/api-asset.response';
import { Organization } from 'shared/api/organization/api-organization.response';
import { OrganizationId, TrackingProviderId } from 'shared/constants/id.constants';

export const settingsState = manageStateSlice({
  organization: {} as Organization,
  timezones: new Array<string>(),
  trackingProviders: new Array<TrackingProvider>(),
})({
  organization: (state, payload: Organization) => {
    state.organization = payload;
  },
  timezones: (state, payload: string[]) => {
    state.timezones = payload;
  },
  trackingProviders: (state, payload: TrackingProvider[]) => {
    state.trackingProviders = payload;
  },
  trackingProvidersAdd: (state, payload: TrackingProvider[]) => {
    state.trackingProviders.push(...payload);
  },
  trackingProvidersUpdate: (state, payload: TrackingProvider) => {
    state.trackingProviders.replace(payload, e => e.id);
  },
  trackingProvidersRemove: (state, payload: TrackingProviderId) => {
    state.trackingProviders.remove(e => e.id === payload);
  },
});
