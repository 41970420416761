import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  OrganizationUserAccount,
  UserAccount,
  UserInvitation,
  UserPersonProfile,
} from 'shared/api/organization/api-organization.response';
import { UserAccountId } from 'shared/constants/id.constants';

export const usersState = manageStateSlice({
  userList: {
    scroll: 0,
    filters: {
      search: '',
    }
  },
  adminList: {
    scroll: 0,
    filters: {
      search: '',
    }
  },
  userPersonProfiles: new Array<UserPersonProfile>(),
  orgUserAccounts: new Array<OrganizationUserAccount>(),
  userAccounts: new Array<UserAccount>(),
  userInvitations: new Array<UserInvitation>(),
})({
  userPersonProfiles: (state, payload: UserPersonProfile[]) => {
    state.userPersonProfiles = payload;
  },
  orgUserAccounts: (state, payload: OrganizationUserAccount[]) => {
    state.orgUserAccounts = payload;
  },
  userAccounts: (state, payload: UserAccount[]) => {
    state.userAccounts = payload;
  },
  userInvitations: (state, payload: UserInvitation[]) => {
    state.userInvitations = payload;
  },
  userInvitationsAdd: (state, payload: UserInvitation[]) => {
    state.userInvitations.push(...payload);
  },
  userInvitationUpdate: (state, payload: UserInvitation) => {
    state.userInvitations.replace(payload, e => e.id);
  },
  userInvitationRemove: (state, email: string) => {
    state.userInvitations.remove(e => e.email === email);
  },
  userListFilters: (state, payload: { search: string }) => {
    state.userList.filters = payload;
  },
  userListScroll: (state, payload: number) => {
    state.userList.scroll = payload;
  },
  adminListFilters: (state, payload: { search: string }) => {
    state.adminList.filters = payload;
  },
  adminListScroll: (state, payload: number) => {
    state.userList.scroll = payload;
  },
  orgUserAccountUpdate: (state, payload: OrganizationUserAccount) => {
    state.orgUserAccounts.replace(payload, e => e.id);
  },
  orgUserAccountRemove: (state, userAccountId: UserAccountId) => {
    state.orgUserAccounts.remove(e => e.userAccountId === userAccountId);
    state.userAccounts.remove(e => e.id === userAccountId);
  },
});
