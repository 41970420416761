<app-shell-page-root>
  <ng-container content>
    <app-user-profile #profile
      [basicsInstruction]="'Provide your full name.'"
      [imageInstruction]="'Add a photo profile picture for other users to easily identify you.'"
      [emailInstruction]="'Your email address is preset. If it is incorrect please contact your administrator.'"
      [emailIsEditable]="false"
      [imageIsEditable]="false"
      [namesAreEditable]="true">
      <ng-container header>
        <div class="page-title">Create your profile</div>
      </ng-container>
      <ng-container footer>
        <button class="submit-button"
          mat-raised-button
          color="primary"
          (click)="onCreate(profile)">
          Create
        </button>
      </ng-container>
    </app-user-profile>
  </ng-container>
</app-shell-page-root>