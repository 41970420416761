import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { G2iAuthService } from 'g2i-ng-auth';
import { AppState } from 'projects/app-admin/src/shared/store/interfaces';
import { concatMap, map } from 'rxjs/operators';
import { ShellPageRootComponent } from 'shared/components/shell-page-root/shell-page-root.component';
import { manageFields } from 'shared/utils/component.utils';
import { pipe } from 'shared/utils/rxjs.utils';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ShellPageRootComponent],
})
export class WelcomeComponent {

  readonly currentOrganizationName$ = this.getCurrentOrganizationName();
  readonly userApplications$ = this.getUserApplications();
  readonly fields = manageFields<WelcomeComponent>(this);

  constructor(
    readonly store: Store<AppState>,
    readonly authService: G2iAuthService,
    readonly router: Router,
  ) {
  }

  private getUserApplications() {
    return pipe(
      concatMap(() => this.store.select(s => s.applications.userApplications)),
      map(uas => uas.map(ua => ({
        id: ua.id,
        name: ua.name,
        description: ua.description,
        appLink: ua.url,
      }))),
    );
  }

  private getCurrentOrganizationName() {
    const orgId = this.router.param.organizationid;
    return pipe(
      concatMap(() => this.authService.authDetails$),
      map(authDetails => {
        const org = authDetails.user.organizations.find(o => o.organization.id === orgId);
        return org ? org.organization.name : 'Unknown';
      })
    );
  }
}
